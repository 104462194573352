@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background: linear-gradient(to right, #b8e5d4, white), top center no-repeat;
  background-size: cover;
}


.header {
  background: url(./images/header.png) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/footer.png) top center no-repeat;
  background-size: cover;
}

.nav {
  background: url(./images/nav.png) top center no-repeat;
  background-size: cover;
}

.ready-order {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)), url(./images/fruits.jpg) fixed center center;
  background-size: cover;
}

.primary-text {
  color: #4695CE;  
}
